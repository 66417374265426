// material-ui
import { useTheme } from '@mui/material/styles';

/**
 * if you want to use image instead of <svg> uncomment following.
 *
 * import logoDark from 'assets/images/logo-dark.svg';
 * import logo from 'assets/images/logo.svg';
 *
 */

// ==============================|| LOGO SVG ||============================== //

const Logo = () => {
    const theme = useTheme();

    return (
        /**
         * if you want to use image instead of svg uncomment following, and comment out <svg> element.
         *
         * <img src={theme.palette.mode === 'dark' ? logoDark : logo} alt="Berry" width="100" />
         *
         */
        <svg width="92" height="32" viewBox="0 0 343 50" fill="none" xmlns="http://www.w3.org/2000/svg">
            <g>
                <path
                    d="M93.52,42.31L77.7,2.99c-.73-1.8-2.48-2.99-4.42-2.99s-3.7,1.18-4.42,2.99l-15.82,39.32c-.59,1.47-.41,3.13,.47,4.45,.89,1.31,2.37,2.1,3.95,2.1h31.64c1.58,0,3.06-.79,3.95-2.1,.89-1.31,1.06-2.98,.47-4.45Z"
                    fill={theme.palette.secondary.main}
                />
                <circle cx="35.28" cy="29.19" r="8.94" fill={theme.palette.secondary.main} />
                <circle cx="8.94" cy="29.19" r="8.94" fill={theme.palette.secondary.main} />
            </g>
            <g>
                <path
                    d="M130.57,35.21h-8.99V18.29c0-.26-.21-.47-.47-.47h-4.45c-.26,0-.47,.21-.47,.47v21.27c0,.26,.21,.47,.47,.47h13.91c.26,0,.47-.21,.47-.47v-3.88c0-.26-.21-.47-.47-.47Z"
                    fill={theme.palette.mode === 'dark' ? theme.palette.common.white : theme.palette.grey[900]}
                />
                <path
                    d="M163.79,17.81h-14.09c-.26,0-.47,.21-.47,.47v21.27c0,.26,.21,.47,.47,.47h14.09c.26,0,.47-.21,.47-.47v-3.8c0-.26-.21-.47-.47-.47h-9.17v-4.23h8.62c.26,0,.47-.21,.47-.47v-3.52c0-.26-.21-.47-.47-.47h-8.62v-4.02h9.17c.26,0,.47-.21,.47-.47v-3.8c0-.26-.21-.47-.47-.47Z"
                    fill={theme.palette.mode === 'dark' ? theme.palette.common.white : theme.palette.grey[900]}
                />
                <path
                    d="M201.71,17.81h-4.79c-.21,0-.39,.14-.45,.34l-4.43,15.17-4.51-15.17c-.06-.2-.24-.34-.45-.34h-5.03c-.15,0-.29,.07-.38,.2s-.11,.28-.06,.43l7.19,21.27c.07,.19,.25,.32,.45,.32h5.38c.2,0,.38-.13,.45-.32l7.09-21.27c.05-.14,.02-.3-.06-.43-.09-.12-.23-.2-.38-.2Z"
                    fill={theme.palette.mode === 'dark' ? theme.palette.common.white : theme.palette.grey[900]}
                />
                <path
                    d="M234.52,17.81h-14.09c-.26,0-.47,.21-.47,.47v21.27c0,.26,.21,.47,.47,.47h14.09c.26,0,.47-.21,.47-.47v-3.8c0-.26-.21-.47-.47-.47h-9.17v-4.23h8.62c.26,0,.47-.21,.47-.47v-3.52c0-.26-.21-.47-.47-.47h-8.62v-4.02h9.17c.26,0,.47-.21,.47-.47v-3.8c0-.26-.21-.47-.47-.47Z"
                    fill={theme.palette.mode === 'dark' ? theme.palette.common.white : theme.palette.grey[900]}
                />
                <path
                    d="M268.21,35.21h-8.99V18.29c0-.26-.21-.47-.47-.47h-4.45c-.26,0-.47,.21-.47,.47v21.27c0,.26,.21,.47,.47,.47h13.91c.26,0,.47-.21,.47-.47v-3.88c0-.26-.21-.47-.47-.47Z"
                    fill={theme.palette.mode === 'dark' ? theme.palette.common.white : theme.palette.grey[900]}
                />
                <path
                    d="M305.2,17.81h-4.45c-.26,0-.47,.21-.47,.47v13.47c0,.61-.09,1.17-.27,1.65-.18,.48-.43,.88-.76,1.22s-.74,.59-1.23,.77c-.99,.37-2.47,.38-3.47,0-.49-.18-.9-.44-1.23-.77s-.58-.74-.76-1.22c-.18-.48-.27-1.04-.27-1.65v-13.47c0-.26-.21-.47-.47-.47h-4.45c-.26,0-.47,.21-.47,.47v13.93c0,1.24,.22,2.39,.67,3.41,.44,1.03,1.09,1.92,1.9,2.65,.82,.73,1.82,1.3,2.97,1.7,1.15,.39,2.44,.59,3.84,.59s2.7-.2,3.84-.59c1.15-.4,2.15-.97,2.97-1.7,.82-.74,1.46-1.63,1.91-2.65,.44-1.02,.67-2.17,.67-3.41v-13.93c0-.26-.21-.47-.47-.47Z"
                    fill={theme.palette.mode === 'dark' ? theme.palette.common.white : theme.palette.grey[900]}
                />
                <path
                    d="M341.62,22.45c-.38-.97-.93-1.8-1.62-2.48s-1.53-1.22-2.49-1.59c-.95-.37-2.02-.56-3.16-.56h-8.87c-.26,0-.47,.21-.47,.47v21.27c0,.26,.21,.47,.47,.47h4.45c.26,0,.47-.21,.47-.47v-6.13h3.73c1.17,0,2.26-.19,3.24-.55,.98-.37,1.85-.9,2.56-1.59,.72-.69,1.28-1.52,1.67-2.48,.39-.95,.59-2.02,.59-3.16s-.19-2.23-.57-3.19Zm-11.22-.04h2.77c1.16,0,2.06,.28,2.66,.82,.6,.54,.89,1.33,.89,2.42s-.29,1.85-.9,2.39c-.61,.55-1.51,.82-2.67,.82h-2.76v-6.46Z"
                    fill={theme.palette.mode === 'dark' ? theme.palette.common.white : theme.palette.grey[900]}
                />
            </g>
        </svg>
    );
};

export default Logo;

// third-party
import { FormattedMessage } from 'react-intl';

// assets
// import { IconUserCheck, IconBasket, IconMessages, IconLayoutKanban, IconMail, IconCalendar, IconNfc } from '@tabler/icons';
import HomeIcon from '@mui/icons-material/Home';
import FestivalIcon from '@mui/icons-material/Festival';
import SubscriptionsIcon from '@mui/icons-material/Subscriptions';
// import VideoLibraryIcon from '@mui/icons-material/VideoLibrary';
import LineStyleIcon from '@mui/icons-material/LineStyle';
import MarginIcon from '@mui/icons-material/Margin';
import MusicNoteIcon from '@mui/icons-material/MusicNote';

// constant
// const icons = {
//     IconUserCheck,
//     IconBasket,
//     IconMessages,
//     IconLayoutKanban,
//     IconMail,
//     IconCalendar,
//     IconNfc
// };

// ==============================|| GCLEVELUP MENU ITEMS ||============================== //

const gclevelup = {
    id: 'gclevelup',
    title: <FormattedMessage id="content" />,
    type: 'group',
    children: [
        {
            id: 'home',
            title: <FormattedMessage id="home" />,
            type: 'item',
            icon: HomeIcon,
            url: '/gclu/home',
            breadcrumbs: false
        },
        {
            id: 'achievement-tour',
            title: <FormattedMessage id="achievement-tour" />,
            type: 'item',
            icon: FestivalIcon,
            url: '/gclu/achievement-tour',
            breadcrumbs: false
        },
        {
            id: 'scales',
            title: <FormattedMessage id="scales" />,
            type: 'item',
            icon: MusicNoteIcon,
            url: '/gclu/scales',
            breadcrumbs: false
        },
        {
            id: 'chords',
            title: <FormattedMessage id="chords" />,
            type: 'item',
            icon: MarginIcon,
            url: '/gclu/chords',
            breadcrumbs: false
        },
        {
            id: 'rhythms',
            title: <FormattedMessage id="rhythms" />,
            type: 'item',
            url: '/gclu/rhythms',
            icon: LineStyleIcon,
            breadcrumbs: false
        },
        {
            id: 'songs-and-more',
            title: <FormattedMessage id="songs-and-more" />,
            type: 'item',
            url: '/gclu/songs-and-more',
            icon: SubscriptionsIcon,
            breadcrumbs: false
        }
    ]
};

export default gclevelup;

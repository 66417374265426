// third-party
import { FormattedMessage } from 'react-intl';

// assets
// import { IconUserCheck, IconBasket, IconMessages, IconLayoutKanban, IconMail, IconCalendar, IconNfc } from '@tabler/icons';
import HistoryIcon from '@mui/icons-material/History';
import OndemandIcon from '@mui/icons-material/OndemandVideo';
import WatchLaterIcon from '@mui/icons-material/WatchLater';
import DashboardIcon from '@mui/icons-material/Dashboard';

// constant
// const icons = {
//     IconUserCheck,
//     IconBasket,
//     IconMessages,
//     IconLayoutKanban,
//     IconMail,
//     IconCalendar,
//     IconNfc
// };

// ==============================|| GCLEVELUP MENU ITEMS ||============================== //

const gcuseritems = {
    id: 'gcuseritems',
    title: <FormattedMessage id="gcuseritems" />,
    type: 'group',
    children: [
        {
            id: 'dashboard',
            title: <FormattedMessage id="dashboard" />,
            type: 'item',
            icon: DashboardIcon,
            url: '/gclu/dashboard',
            breadcrumbs: false
        },
        {
            id: 'history',
            title: <FormattedMessage id="history" />,
            type: 'item',
            icon: HistoryIcon,
            url: '/gclu/history',
            breadcrumbs: false
        },
        {
            id: 'your-videos',
            title: <FormattedMessage id="your-videos" />,
            type: 'item',
            url: '/gclu/your-videos',
            icon: OndemandIcon,
            breadcrumbs: false
        },
        {
            id: 'play-later',
            title: <FormattedMessage id="play-later" />,
            type: 'item',
            url: '/gclu/play-later',
            icon: WatchLaterIcon,
            breadcrumbs: false
        }
    ]
};

export default gcuseritems;
